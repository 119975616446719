import React from 'react';
import {Dropdown, DropdownOptionProps, TPAComponentsProvider} from 'wix-ui-tpa';
import {CurrencySymbols, GetCurrencyDisplayedValue} from '../../../settings/services/currency.utility';
import {classes as st} from './CurrenciesDropdown.st.css';

export interface CurrenciesDropdownProps {
  currencies: string[];
  selectedCurrency: string;

  onChange: (code: string) => void;

  currencySymbols: CurrencySymbols;
  isMobile: boolean;
  isRtl: boolean;
  translations: any;
  style?: {height?: number};
}

interface CurrenciesDropdownState {
  currencyOptions: DropdownOptionProps[];
}

export class CurrenciesDropdown extends React.Component<CurrenciesDropdownProps, CurrenciesDropdownState> {
  constructor(props: CurrenciesDropdownProps) {
    super(props);

    const {currencies, currencySymbols} = props;
    this.state = {
      currencyOptions: this.getCurrencyOptions(currencies, currencySymbols),
    };
  }

  private readonly getTranslationByKey = (key: string) => {
    const {translations} = this.props;
    /* istanbul ignore next: need test - empty value */
    return translations?.[key] ? translations[key] : '';
  };

  /* istanbul ignore next: need test - currencies default value */
  private readonly getCurrencyOptions = (currencies = [], currencySymbols = {}) => {
    return currencies.map((currency) => ({
      id: currency,
      value: GetCurrencyDisplayedValue(
        currency,
        currencySymbols,
        this.getTranslationByKey('currencyConverter.dropdown.defaultCurrency.text')
      ),
      isSelectable: true,
    }));
  };

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<CurrenciesDropdownProps>): void {
    /* istanbul ignore next: need test - currencies default */
    const {currencies = [], currencySymbols} = nextProps;

    this.setState({
      currencyOptions: this.getCurrencyOptions(currencies, currencySymbols),
    });
  }

  private getKeyForHackRenderOfDropdown() {
    const {selectedCurrency, currencySymbols} = this.props;
    return GetCurrencyDisplayedValue(
      selectedCurrency,
      currencySymbols,
      this.getTranslationByKey('currencyConverter.dropdown.defaultCurrency.text')
    );
  }

  public render() {
    const {currencyOptions} = this.state;
    const {onChange, selectedCurrency, isMobile, isRtl, style} = this.props;

    return (
      <div data-hook="currency-converter-container" style={style}>
        <TPAComponentsProvider value={{mobile: isMobile, rtl: isRtl}}>
          <Dropdown
            className={st.root}
            aria-label={this.getTranslationByKey('sr.currencyConverter.widget')}
            key={this.getKeyForHackRenderOfDropdown()}
            data-hook="currencies-dropdown"
            options={currencyOptions}
            initialSelectedId={selectedCurrency}
            onChange={(option: DropdownOptionProps) => onChange(option.id)}
          />
        </TPAComponentsProvider>
      </div>
    );
  }
}
